.App {
  padding: 1em;
}

th {
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

tr:nth-child(2n) td {
  background-color: #eee;
}

td,th {
  min-width: 5em;
  padding: 0 0.5em;
  line-height: 1.75em;
}

table {
  width: 100%;
}

td input {
  width: 5em;
  font-size: 1em;
}

label {
  display: block;
  margin-bottom: 0.5em;
}

.grid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 10px;
  grid-template-areas: 
    "temp development"
    "table chart";
}

@media(max-width: 768px) {
  .grid {
    display: block;
  }

  .grid > * {
    margin: 1em 0;
  }
}

.development {
  grid-area: development;
}

h2 {
  margin-top: 0;
}

h1 {
  border-bottom: 2px solid black;
  font-size: 2rem;
  padding-bottom: 0.5em;
}

.table {
  grid-area: table;
  overflow-y: auto;
  max-height: 400px;
}

.chart {
  grid-area: chart;
}

.set-temp {
  grid-area: temp;
}

* {
  box-sizing: border-box;
}

body {
  background: white;
  margin: 0;
  padding: 1em; 
  font-family: 'Montserrat', sans-serif;
  box-sizing: content-box;
}

button.btn, .btn::file-selector-button {
  color: white;
  background-color: rgb(190, 0, 0);
  text-shadow: 0.025em 0.025em black;
  display: inline-block;
  font-weight: bold;
  border-radius: 0.125em;
  text-decoration: none;
  outline: 0;
  border: 0;
  font-family: 'Montserrat', sans-serif;
  padding: 0.5em 1em;
  font-size: 1.2em;
  margin: 1em 0;
  cursor: pointer;
  transition: 200ms all;
}

hr {
  border: 0;
  border-bottom: 2px solid black;
  margin: 2em 0;
}

.btn::file-selector-button {
  margin-right: 10px;
}

.input-wrapper input {
  display: block;
  border: none;
  padding: 10px 5px;
  border: solid 1px black;
  transition: all 0.3s cubic-bezier(.64,.09,.08,1);
  background: linear-gradient(to bottom, rgba(255,255,255,0) 96%, black 4%);
  background-position: -100vw 0;
  background-size: 100vw 100%;
  background-repeat: no-repeat;
  color: darken(black, 20%);
  width: 100%;
}

input:focus, input:not(:placeholder-shown) {
  box-shadow: none;
  outline: none;
  background-position: 0 0;
}

.input-wrapper {
  position: relative;
  width: 200px;
  padding-top: 25px;
}

.input-wrapper.full {
  width: 100%;
}

.input-wrapper label {
  position: absolute;
  bottom: 2px;
  left: 5px;
  transition: all 0.3s;
  pointer-events: none;
}

.input-wrapper input::placeholder {
  color: transparent;
}

.input-wrapper input::-webkit-input-placeholder {
  color: transparent;
}

input:focus + label, input:not(:placeholder-shown) + label {
  color: black;
  font-size: 11px;
  transform: translateY(-35px);
  left: 0;
  visibility: visible !important;
}